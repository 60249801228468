import React from "react";
import { Box } from "@mui/material";
import { Range, getTrackBackground } from "react-range";

export default function RangeBar({ values, setValues }) {
  const MIN = 0;
  const MAX = 700;
  const STEP = 10;
  return (
    <Box width={{ lg: 500, md: 500, xs: "95%" }}>
      <Range
        step={STEP}
        min={MIN}
        max={MAX}
        values={values}
        onChange={(e) => setValues(e)}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              width: "100%",
              height: "36px",
              display: "flex",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "40px",
                width: "100%",
                borderRadius: "4px",
                alignSelf: "center",
                background: getTrackBackground({
                  colors: [
                    "rgba(141, 112, 110, 0.47)",
                    "rgba(141, 112, 110, 0.1)",
                  ],
                  values: values,
                  min: MIN,
                  max: MAX,
                }),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              width: "42px",
              height: "42px",
              display: "flex",
              borderRadius: "4px",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#6a5555",
              boxShadow: "0px 2px 6px gray",
            }}
          >
            <div
              style={{
                width: "5px",
                height: "16px",
                backgroundColor: isDragged ? "#ffffff" : "#CCC",
              }}
            />
          </div>
        )}
      />
    </Box>
  );
}
