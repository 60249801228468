import "./Pricing.css";
import logo from "../assets/unyte-maddie.png";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { doc, updateDoc, database } from "../db/firebase";
import { Done, Mouse, LocationOn } from "@mui/icons-material";

export default function Pricing() {
  const navigate = useNavigate();
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [docid, setDocId] = useState("");
  const [savings, setSavings] = useState("");
  const [address, setAddress] = useState("");
  const [endPrice, setEndPrice] = useState(0);
  const [taxCredit, setTaxCredit] = useState(0);
  const [homeValue, setHomeValue] = useState("");
  const [startPrice, setStartPrice] = useState(0);
  const [systemSize, setSystemSize] = useState("");
  const [solarPanels, setSolarPanels] = useState("");
  const [tonesOffset, setTonesOffset] = useState("");
  const [monthlySavings, setMonthlySavings] = useState("");
  const key = "AIzaSyBeoDwIofUJph_Mwdi6GvX7PuLVOVzM9oc";

  useEffect(() => {
    let search = window.location.search.replace("?", "").split("&");
    if (window.location.search) {
      let id = search.find((e) => e.includes("docId")).split("=")[1];
      let price = search.find((e) => e.includes("electricity")).split("=")[1];
      let firstName = search.find((e) => e.includes("firstName")).split("=")[1];
      let lastName = search.find((e) => e.includes("lastName")).split("=")[1];
      let email = search.find((e) => e.includes("email")).split("=")[1];
      let lat = search.find((e) => e.includes("lat")).split("=")[1];
      let lng = search.find((e) => e.includes("lng")).split("=")[1];
      let address = search
        .find((e) => e.includes("address"))
        .split("=")[1]
        .replaceAll("%20", " ");

      let multiplierHigh = (price / 100) * 20;
      let multiplierLow = (price / 100) * 10;

      setMonthlySavings(multiplierHigh);
      setName(firstName + " " + lastName);
      setStartPrice(price - multiplierHigh);
      setEndPrice(price - multiplierLow);
      setAddress(address);
      setEmail(email);
      setDocId(id);
      setLat(lat);
      setLng(lng);

      if (price > 75 && price < 120) {
        setTonesOffset("3.46 - 4.81");
        setSystemSize("5.12 - 7.02");
        setSolarPanels("17 - 22");
        setHomeValue("15,271");
        setTaxCredit("9,984");
        setSavings("24,784");
      }
      if (price >= 120 && price < 200) {
        setTonesOffset("7.65 - 9.07");
        setSystemSize("9.75 - 14.13");
        setSolarPanels("34 - 43");
        setHomeValue("29,821");
        setTaxCredit("13,125");
        setSavings("39,765");
      }
      if (price >= 200 && price < 300) {
        setTonesOffset("10.72 - 13.82");
        setSystemSize("15.82 - 21.75");
        setSolarPanels("51 - 64");
        setHomeValue("38,972");
        setTaxCredit("17,385");
        setSavings("51,235");
      }
      if (price >= 300 && price < 400) {
        setTonesOffset("14.32 - 19.07");
        setSystemSize("22.61 - 29.79");
        setSolarPanels("70 - 80");
        setHomeValue("53,912");
        setTaxCredit("21,932");
        setSavings("64,971");
      }
      if (price >= 400 && price < 500) {
        setTonesOffset("15.32 - 20.07");
        setSystemSize("23.61 - 30.79");
        setSolarPanels("73 - 84");
        setHomeValue("55,912");
        setTaxCredit("23,932");
        setSavings("67,971");
      }
      if (price >= 500 && price < 600) {
        setTonesOffset("16.32 - 21.07");
        setSystemSize("24.61 - 31.79");
        setSolarPanels("76 - 86");
        setHomeValue("57,912");
        setTaxCredit("25,932");
        setSavings("69,971");
      }
      if (price >= 600 && price < 700) {
        setTonesOffset("17.32 -22.07");
        setSystemSize("25.61 - 32.79");
        setSolarPanels("78 - 89");
        setHomeValue("60,912");
        setTaxCredit("27,932");
        setSavings("71,971");
      }
    } else navigate("/");
  }, [navigate]);

  const handleContact = async () => {
    await updateDoc(doc(database, "users", docid), { email, contact: true });
    navigate("/contact");
  };

  return (
    <div className="estimate-container">
      <div className="header">
        <img
          src={require("../assets/breakdownIcons/logo.png")}
          onClick={() => navigate("/")}
          alt="logo"
          className="logo-header"
        />
      </div>
      <div className="sectionRow">
        <div className="sectionColumn">
          <h1 className="estimate-head">
            <span className="colored">Hey</span>, {name}!
          </h1>
          <h1 className="estimate-heading">Here is your New Estimate!</h1>
          <h1 className="estimate-price">
            ${startPrice} - ${endPrice}
          </h1>
          <p className="estimate-saving">
            You will save <span className="saving">~${monthlySavings}</span>{" "}
            /Month
          </p>
          <p className="estimate-description">
            Estimated cost with financing a 100% energy bill offset. Keep
            reading to learn more about your system, savings, and additional
            bonuses.
          </p>
          <div className="addressBar">
            <LocationOn className="addressBarIcon" />
            <span>{address}</span>
          </div>
          <Button
            variant="contained"
            startIcon={
              <img className="KeyboardVoice" src={logo} alt="SolarSaver Logo" />
            }
            onClick={handleContact}
            className="solarPro-button animate__animated animate__fadeIn"
          >
            Speak to a solar pro
          </Button>
        </div>
        <div className="sectionColumn2">
          <iframe
            title="MapFrame"
            className="mapFrame"
            src={`https://www.google.com/maps/embed/v1/view?key=${key}&center=${lat},${lng}&zoom=18&maptype=satellite`}
          />
        </div>
      </div>
      <div className="scrollButton">
        <Mouse color="#FA531E" />
        <span>Scroll for More</span>
      </div>
      <div className="breakdown-box">
        <span className="breakdown-small-text">Breakdown</span>
        <h1 className="breakdown-text">Breakdown</h1>
        <p className="solar-estimate">Check Out Your Free Solar Estimate!</p>
        <Grid className="estimate-row">
          <Box className="estimate-card">
            <img
              className="estimateIcon"
              src={require("../assets/breakdownIcons/Icon.png")}
              alt="Icon of Dollar sign"
            />
            <h5 className="breakdown-small-text">25 Year Savings</h5>
            <h3>${savings}*</h3>
            <p className="breakdown-info">
              Everyone loves extra cash in their pocket when.
            </p>
          </Box>
          <Box className="estimate-card">
            <img
              className="estimateIcon"
              src={require("../assets/breakdownIcons/Icon-2.png")}
              alt="percent icon"
            />
            <h5 className="breakdown-small-text">Tax Credits</h5>
            <h3>${taxCredit}*</h3>
            <p className="breakdown-info">
              Everyone loves extra cash in their pocket when.
            </p>
          </Box>
          <Box className="estimate-card">
            <img
              className="estimateIcon"
              src={require("../assets/breakdownIcons/Icon-3.png")}
              alt="home icon"
            />
            <h5 className="breakdown-small-text">Added Home Value</h5>
            <h3>${homeValue}*</h3>
            <p className="breakdown-info">
              Everyone loves extra cash in their pocket when.
            </p>
          </Box>
        </Grid>
        <Button
          variant="contained"
          onClick={handleContact}
          startIcon={
            <img className="KeyboardVoice" src={logo} alt="SolarSaver Logo" />
          }
          className="solarPro-button animate__animated animate__fadeIn"
        >
          Speak to a solar pro
        </Button>
      </div>
      <div className="home-box">
        <div className="dashboard-col">
          <h1 className="home-box-heading">
            The new way to find your new home
          </h1>
          <div className="home-info-row">
            <Done className="doneIcon" />
            <p>
              A solar system’s size refers to the top output of electricity the
              system is capable of generating.
            </p>
          </div>
          <div className="home-info-row">
            <Done className="doneIcon" />
            <p>
              The estimated number of panels your home will require to meet your
              energy needs.
            </p>
          </div>
          <Grid className="home-box-row">
            <Box className="home-box-col">
              <img
                className="home-box-icon"
                src={require("../assets/breakdownIcons/solar.png")}
                alt="solar Icon"
              />
              <h3>{systemSize}kWh*</h3>
              <p className="breakdown-info">System size</p>
            </Box>
            <Box className="home-box-col">
              <img
                className="home-box-icon"
                src={require("../assets/breakdownIcons/eye.png")}
                alt="Graph icon"
              />
              <h3>{solarPanels}*</h3>
              <p className="breakdown-info">Solar Panels</p>
            </Box>
            <Box className="home-box-col">
              <img
                className="home-box-icon"
                src={require("../assets/breakdownIcons/flower.png")}
                alt="plant icon"
              />
              <h3>{tonesOffset}*</h3>
              <p className="breakdown-info">
                US Tonnes of CO2 Offset Per Year Size
              </p>
            </Box>
          </Grid>
        </div>
        <img
          src={require("../assets/breakdownIcons/house.png")}
          alt="house Icon"
          className="houseIcon"
        />
      </div>
      <div className="butwrapper">
        <Button
          variant="contained"
          onClick={handleContact}
          startIcon={
            <img className="KeyboardVoice" src={logo} alt="SolarSaver Logo" />
          }
          className="solarPro-button animate__animated animate__fadeIn"
        >
          Speak to a solar pro
        </Button>
      </div>

      <div className="testimonial-box">
        <h1 className="testimonial-h1">Testimonials</h1>
        <p className="testimonial-p">
          See what homeowners, property managers, and businesses have to say
        </p>
        <h4 className="testimonial-h4">
          “If you're looking for a solar company that can help you estimate your
          solar cost, Solar Saver is a great option. They have a user-friendly
          online calculator that can give you a estimate of how much your solar
          project will cost. They also offer a free consultation to help you
          understand your options and find the best solar solution for your
          needs. Solar Saver is a great choice for anyone looking for a
          reputable and affordable solar company.”
        </h4>
      </div>

      <div className="contact-box">
        <img src={logo} className="contact-logo" alt="logo" />
        <p className="contact-promise">Free Solar Estimate!</p>
        <h3 className="contact-heading">Speak with a solar pro!</h3>
        <h3 className="contact-heading">
          To Save <span className="colored">~${monthlySavings}</span>/Month
        </h3>
        <p className="contact-info">
          Discover ways to increase your home's value and get listed. No Spam.
        </p>
        <div className="butwrapper">
          <Button
            variant="contained"
            onClick={handleContact}
            startIcon={
              <img className="KeyboardVoice" src={logo} alt="SolarSaver Logo" />
            }
            className="solarPro-button animate__animated animate__fadeIn"
          >
            Speak to a solar pro
          </Button>
        </div>
      </div>

      <div className="footerBox">
        <img
          src={require("../assets/breakdownIcons/logo.png")}
          alt="logo"
          className="logo-header"
        />
        <div className="footer-row">
          <span>
            ©2022 solarsaver. <br></br>All rights reserved
          </span>
        </div>
        <div className="icons-row">
          {/* <Facebook className="footer-icon" /> */}
          {/* <Twitter className="footer-icon" /> */}
          {/* <Instagram className="footer-icon" /> */}
        </div>
      </div>
    </div>
  );
}
