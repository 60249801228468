import {
  Box,
  Button,
  // Checkbox,
  TextField,
  Typography,
  Autocomplete,
  // FormControlLabel,
} from "@mui/material";
import "./services.css";
import Swal from "sweetalert2";
import Lottie from "react-lottie";
import { Row, Col } from "reactstrap";
import React, { useState } from "react";
import RangeBar from "../components/range";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/unyte-maddie.png";
import { useNavigate } from "react-router-dom";
import house1 from "../assets/images/house1.json";
import house2 from "../assets/images/house2.json";
import house3 from "../assets/images/house7.json";
import house4 from "../assets/images/house4.json";
import { ArrowBackIos } from "@mui/icons-material";
import PlacesAutocomplete from "react-places-autocomplete";
import { getAnalytics, logEvent } from "firebase/analytics";
import { database, addDoc, collection } from "../db/firebase";
import { getLatLng, geocodeByAddress } from "react-places-autocomplete";

const key = "AIzaSyBeoDwIofUJph_Mwdi6GvX7PuLVOVzM9oc";

export default function Services() {
  const navigate = useNavigate();
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [values, setValues] = useState([130]);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  // const [allowEmail, setAllowEmail] = useState(false);

  // GOOGLE ANALYTICS
  const analytics = getAnalytics();
  logEvent(analytics, "started_funnel", {
    content_type: "page_view",
    items: [
      {
        item_id: "services",
        item_name: "services",
      },
    ],
  });

  logEvent(analytics, "sign_up", {
    method: "email",
  });

  logEvent(analytics, "select_content", {
    content_type: "address",
    item_id: "submit_address",
  });

  const options = {
    loop: false,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      className: "home-img",
    },
  };

  const saveUser = async () => {
    if (firstName === "" || lastName === "" || email === "" || phone === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill Your information",
      });
    } else {
      addDoc(collection(database, "users"), {
        lat,
        lng,
        phone,
        email,
        address,
        lastName,
        firstName,
        // allowEmail,
        sent: false,
        contact: false,
        electricity: values[0],
      })
        .then((doc) => {
          navigate(
            `/pricing?address=${address}&email=${email}&phone=${phone}&firstName=${firstName}&lastName=${lastName}&electricity=${values[0]}&docId=${doc.id}&lat=${lat}&lng=${lng}`
          );
        })
        .catch((er) => {
          Swal.fire({ text: er, icon: "error", title: "Oops..." });
        });
    }
  };

  const handleSelect = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setLat(latLng.lat);
        setLng(latLng.lng);
      })
      .catch((error) => console.error("Error", error));
  };

  const searchOptions = {
    componentRestrictions: { country: ["us"] },
  };

  return (
    <div id="services">
      <Box width={{ lg: "95%", md: "95%", sm: "95%", xs: "95%" }}>
        <Box
          width={"100%"}
          marginTop={"20px"}
          alignItems="center"
          flexDirection={"row"}
          justifyContent={"center"}
        >
          {activeStep === 0 && (
            <Box className="card-box">
              <img
                alt="Maddie"
                width={90}
                src={logo}
                className="animate__animated animate__fadeIn"
              />
              <Typography
                marginTop={1}
                textAlign="center"
                color="text.primary"
                fontSize={{ lg: 20, md: 20, sm: 20, xs: 20 }}
                className="animate__animated animate__fadeIn"
              >
                Hi! I'm Maddie.
              </Typography>
              <Typography className="animate__animated animate__fadeIn card-about">
                FIND OUT IN SECONDS
              </Typography>
              <Typography
                fontWeight="bold"
                textAlign="center"
                color="text.primary"
                fontFamily={"'Nunito Sans', sans-serif"}
                marginTop={{ lg: 1, md: 1, sm: 1, xs: 0 }}
                fontSize={{ lg: 40, md: 35, sm: 25, xs: 15 }}
                className="animate__animated animate__fadeIn"
                lineHeight={{ lg: "55px", md: "45px", sm: "30px", xs: "30px" }}
              >
                Let's get your solar estimate
              </Typography>
              <Typography
                color="text.primary"
                textAlign={"center"}
                marginTop={{ lg: 2, md: 2, sm: 2, xs: 0 }}
                fontSize={{ lg: 24, md: 20, sm: 15, xs: 15 }}
                className="animate__animated animate__fadeIn"
              >
                WHAT IS YOUR ADDRESS?
              </Typography>
              <br />
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
                searchOptions={searchOptions}
              >
                {({ getInputProps, suggestions }) => (
                  <div style={{ width: "90%" }}>
                    <Autocomplete
                      disablePortal
                      onChange={(e, value) => handleSelect(value)}
                      options={suggestions.map((e) => e.description)}
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <input
                            {...params.inputProps}
                            {...getInputProps({
                              className: "input",
                              placeholder: "Address",
                            })}
                          />
                        </div>
                      )}
                    />
                  </div>
                )}
              </PlacesAutocomplete>

              <br />
              <Button
                variant="contained"
                id="submit_address"
                onClick={() =>
                  address
                    ? setActiveStep(activeStep + 1)
                    : Swal.fire({
                        title: "Please enter your address",
                        icon: "error",
                      })
                }
                className="card-button animate__animated animate__fadeIn"
              >
                Submit
              </Button>
            </Box>
          )}
          {activeStep === 1 && (
            <Box className="card-box">
              <ArrowBackIos
                style={{
                  top: "20px",
                  left: "20px",
                  cursor: "pointer",
                  position: "absolute",
                }}
                onClick={() => setActiveStep(activeStep - 1)}
              />
              <img
                alt="Anne"
                width={90}
                src={logo}
                className="animate__animated animate__fadeIn"
              />
              <Typography
                marginTop={1}
                fontWeight="700"
                textAlign="center"
                color="text.primary"
                fontSize={{ lg: 16, md: 16, sm: 16, xs: 16 }}
                className="animate__animated animate__fadeIn"
              >
                How does this look?
              </Typography>
              <Typography
                marginTop={1}
                marginBottom={0}
                color="#614F4F"
                fontWeight="bold"
                textAlign="center"
                fontSize={{ lg: 22, md: 20, sm: 20, xs: 18 }}
                className="animate__animated animate__fadeIn"
              >
                Just to confirm,
              </Typography>
              <Typography
                marginTop={-1}
                color="#614F4F"
                marginBottom={1}
                fontWeight="bold"
                textAlign="center"
                fontFamily={"'Nunito Sans', sans-serif"}
                fontSize={{ lg: 30, md: 30, sm: 30, xs: 25 }}
                className="animate__animated animate__fadeIn"
              >
                is this your house?
              </Typography>
              <iframe
                title="MapFrame"
                src={`https://www.google.com/maps/embed/v1/view?key=${key}&center=${lat},${lng}&zoom=20&maptype=satellite`}
              />
              <br />
              <Button
                variant="contained"
                onClick={() => setActiveStep(activeStep + 1)}
                className="card-button animate__animated animate__fadeIn"
              >
                Submit
              </Button>
            </Box>
          )}
          {activeStep === 2 && (
            <Box className="card-box">
              <ArrowBackIos
                style={{
                  top: "20px",
                  left: "20px",
                  cursor: "pointer",
                  position: "absolute",
                }}
                onClick={() => setActiveStep(activeStep - 1)}
              />
              <img
                alt="Anne"
                width={90}
                src={logo}
                className="animate__animated animate__fadeIn"
              />
              <Typography className="animate__animated animate__fadeIn card-about">
                YOUR CURRENT COSTS
              </Typography>
              <Typography
                textAlign="center"
                fontWeight={"bold"}
                color="text.primary"
                width={{ lg: 500, md: 500, xs: "97%" }}
                fontFamily={"'noto-sans-bold', sans-serif"}
                fontSize={{ lg: 35, md: 20, sm: 20, xs: 20 }}
                lineHeight={{ lg: "40px", md: "25px", sm: "25px", xs: "25px" }}
              >
                WHAT IS YOUR AVERAGE ELECTRICITY BILL?
              </Typography>
              <br />
              <Typography
                color="#6a5555"
                textAlign="center"
                fontWeight={"bold"}
                width={{ lg: 500, md: 500, xs: "97%" }}
                fontFamily={"'noto-sans-bold', sans-serif"}
                fontSize={{ lg: 35, md: 20, sm: 22, xs: 22 }}
                lineHeight={{ lg: "40px", md: "25px", sm: "25px", xs: "25px" }}
              >
                ${values[0]}.00
              </Typography>
              <br />
              <RangeBar values={values} setValues={setValues} />
              <br />
              <p style={{ color: "gray", textAlign: "center" }}>
                We use this info to estimate your plan and savings!
              </p>
              <Button
                onClick={() => setActiveStep(activeStep + 1)}
                className="animate__animated animate__fadeIn card-button"
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          )}
          {/* {activeStep === 3 && (
            <Box className="card-box">
              <ArrowBackIos
                style={{
                  top: "20px",
                  left: "20px",
                  cursor: "pointer",
                  position: "absolute",
                }}
                onClick={() => setActiveStep(activeStep - 1)}
              />
              <img
                alt="Anne"
                width={90}
                src={Avatar}
                className="animate__animated animate__fadeIn"
              />
              <Typography className="animate__animated animate__fadeIn card-about">
                ABOUT YOUR HOME
              </Typography>
              <Typography
                textAlign="center"
                color="text.primary"
                fontWeight={"bolder"}
                width={{ lg: 550, md: 550, xs: "90%" }}
                fontFamily={"'noto-sans-bold', sans-serif"}
                fontSize={{ lg: 35, md: 20, sm: 17, xs: 17 }}
                lineHeight={{ lg: "40px", md: "25px", sm: "25px", xs: "25px" }}
              >
                DO YOU OWN OR RENT YOUR HOME?
              </Typography>
              <div className="home-img-row">
                <div
                  className="home-img-box"
                  onClick={() => setActiveStep(activeStep + 1)}
                >
                  <Lottie options={{ ...options, animationData: house6 }} />
                  <Typography className="home-box-text" fontWeight={"bold"}>
                    Own
                  </Typography>
                </div>
                <div
                  className="home-img-box"
                  onClick={() => setActiveStep(activeStep + 1)}
                >
                  <Lottie options={{ ...options, animationData: house5 }} />
                  <Typography className="home-box-text" fontWeight={"bold"}>
                    Rent
                  </Typography>
                </div>
              </div>
            </Box>
          )} */}
          {activeStep === 3 && (
            <Box className="card-box">
              <ArrowBackIos
                style={{
                  top: "20px",
                  left: "20px",
                  cursor: "pointer",
                  position: "absolute",
                }}
                onClick={() => setActiveStep(activeStep - 1)}
              />
              <img
                alt="Anne"
                width={90}
                src={logo}
                className="animate__animated animate__fadeIn"
              />
              <Typography className="animate__animated animate__fadeIn card-about">
                ABOUT YOUR HOME
              </Typography>
              <Typography
                textAlign="center"
                i
                color="text.primary"
                fontWeight={"bolder"}
                width={{ lg: 550, md: 550, xs: "90%" }}
                fontFamily={"'noto-sans-bold', sans-serif"}
                fontSize={{ lg: 35, md: 20, sm: 17, xs: 17 }}
                lineHeight={{ lg: "40px", md: "25px", sm: "25px", xs: "25px" }}
              >
                WHAT TYPE OF HOUSE DO YOU HAVE?
              </Typography>
              <div className="home-img2-row">
                <div
                  className="home-img2-box"
                  onClick={() => setActiveStep(activeStep + 1)}
                >
                  <Lottie options={{ ...options, animationData: house1 }} />
                  <Typography className="home-box-text" fontWeight={"bold"}>
                    Apartment
                  </Typography>
                </div>
                <div
                  className="home-img2-box"
                  onClick={() => setActiveStep(activeStep + 1)}
                >
                  <Lottie options={{ ...options, animationData: house4 }} />
                  <Typography className="home-box-text" fontWeight={"bold"}>
                    Duplex
                  </Typography>
                </div>
                <div
                  className="home-img2-box"
                  onClick={() => setActiveStep(activeStep + 1)}
                >
                  <Lottie options={{ ...options, animationData: house3 }} />
                  <Typography className="home-box-text" fontWeight={"bold"}>
                    Mobile Home
                  </Typography>
                </div>
                <div
                  className="home-img2-box"
                  onClick={() => setActiveStep(activeStep + 1)}
                >
                  <Lottie options={{ ...options, animationData: house2 }} />
                  <Typography className="home-box-text" fontWeight={"bold"}>
                    Single Home
                  </Typography>
                </div>
              </div>
            </Box>
          )}
          {activeStep === 4 && (
            <Box className="card-box">
              <ArrowBackIos
                style={{
                  top: "20px",
                  left: "20px",
                  cursor: "pointer",
                  position: "absolute",
                }}
                onClick={() => setActiveStep(activeStep - 1)}
              />
              <img
                alt="Anne"
                width={90}
                src={logo}
                className="animate__animated animate__fadeIn"
              />
              <Typography className="animate__animated animate__fadeIn card-about">
                ABOUT YOUR HOME
              </Typography>
              <Typography
                textAlign="center"
                color="text.primary"
                fontWeight={"bolder"}
                fontFamily={"'noto-sans-bold', sans-serif"}
                fontSize={{ lg: 35, md: 25, sm: 25, xs: 25 }}
                lineHeight={{ lg: "40px", md: "25px", sm: "25px", xs: "25px" }}
              >
                Personal Info
              </Typography>
              <br />
              <Row style={{ width: "100%" }}>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    value={firstName}
                    label="First Name"
                    variant="outlined"
                    onChange={(e) => setFirstName(e.target.value)}
                    style={{
                      background: "white",
                      borderRadius: "5px",
                      marginBottom: "20px",
                    }}
                  />
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    value={lastName}
                    label="Last Name"
                    variant="outlined"
                    onChange={(e) => setLastName(e.target.value)}
                    style={{
                      background: "white",
                      borderRadius: "5px",
                      marginBottom: "20px",
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ width: "100%" }}>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Phone"
                    value={phone}
                    variant="outlined"
                    onChange={(e) => setPhone(e.target.value)}
                    style={{
                      background: "white",
                      borderRadius: "5px",
                      marginBottom: "20px",
                    }}
                  />
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    value={email}
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                      background: "white",
                      borderRadius: "5px",
                      marginBottom: "20px",
                    }}
                  />
                </Col>
              </Row>
              {/* <div style={{ width: "96%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allowEmail}
                      onChange={() => setAllowEmail(!allowEmail)}
                    />
                  }
                  label="Allow Solar Saver to send estimate on email address"
                />
              </div> */}
              <br />
              <Button
                className="animate__animated animate__fadeIn card-button"
                variant="contained"
                onClick={saveUser}
              >
                Show the Amount
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
}
