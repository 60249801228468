import "./Pricing.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Button } from "@mui/material";
import { Done, Twitter, Facebook, Instagram } from "@mui/icons-material";

export default function Pricing() {
  const navigate = useNavigate();

  const handleContact = async () => {
    navigate("/services");
  };

  return (
    <div className="dashboard-container">
      <div className="header">
        <img
          src={require("../assets/breakdownIcons/logo.png")}
          onClick={() => navigate("/")}
          className="logo-header"
          alt="logo"
        />
      </div>

      <div className="dashboard-box">
        <div className="dashboard-col">
          <h1 className="home-box-heading">Solar Calculator</h1>
          {/* <h4>Estimate your energy bill savings in seconds!</h4> */}
          <div className="home-info-row">
            <Done className="doneIcon" />
            <p>
              A solar system’s size refers to the top output of electricity the
              system is capable of generating.
            </p>
          </div>
          <div className="home-info-row">
            <Done className="doneIcon" />
            <p>
              The estimated number of panels your home will require to meet your
              energy needs.
            </p>
          </div>
          <Grid className="home-box-row">
            <Box className="home-box-col">
              <img
                className="home-box-icon"
                src={require("../assets/breakdownIcons/solar.png")}
                alt="solar Icon"
              />
              <h3>System Size</h3>
              <p className="breakdown-info">See your Wh*</p>
            </Box>
            <Box className="home-box-col">
              <img
                className="home-box-icon"
                src={require("../assets/breakdownIcons/eye.png")}
                alt="Graph icon"
              />
              <h3>Solar Panels</h3>
              <p className="breakdown-info">How many Solar Panels</p>
            </Box>
            <Box className="home-box-col">
              <img
                className="home-box-icon"
                src={require("../assets/breakdownIcons/flower.png")}
                alt="plant icon"
              />
              <h3>Carbon Offset</h3>
              <p className="breakdown-info">
                US Tonnes of CO2 Offset Per Year Size
              </p>
            </Box>
          </Grid>
        </div>
        <img
          src={require("../assets/breakdownIcons/house.png")}
          alt="house Icon"
          className="houseIcon"
        />
      </div>
      <Button
        variant="contained"
        onClick={handleContact}
        className="solarPro-button-main animate__animated animate__fadeIn"
      >
        CALCULATE YOUR SAVINGS
      </Button>
      <div className="dashboard-testimonial-box">
        <h1 className="testimonial-h1">Testimonials</h1>
        <p className="testimonial-p">
          See what our property managers, landlords, and tenants have to say
        </p>
        <h4 className="testimonial-h4">
          “If you're looking for a solar company that can help you estimate your
          solar cost, Solar Saver is a great option. They also offer a free
          consultation to help you understand your options and find the best
          solar solution for your needs. Solar Saver is a great choice for
          anyone looking for a reputable and affordable solar company.”
        </h4>
      </div>
      <div className="footerBox">
        <img
          src={require("../assets/breakdownIcons/logo.png")}
          alt="logo"
          className="logo-header"
        />
        <div className="footer-row">
          <span>©2022 solarsaver. All rights reserved</span>
        </div>
        <div className="icons-row">
          <Facebook className="footer-icon" />
          <Twitter className="footer-icon" />
          <Instagram className="footer-icon" />
        </div>
      </div>
    </div>
  );
}
