import "./services.css";
import React from "react";
import check from "../assets/Checked.png";
// import Avatar from "../assets/avatar.svg";
import logo from "../assets/unyte-maddie.png";
import "bootstrap/dist/css/bootstrap.min.css";
// import { useNavigate } from "react-router-dom";
// import border from "../assets/images/border.svg";
import { Button, Box, Typography } from "@mui/material";

export default function Services() {
  // const navigate = useNavigate();
  return (
    <div id="services">
      {/* <img src={border} alt="border" className="borderTop" /> */}
      {/* <img src={border} alt="border" className="borderBottom" /> */}
      <Box width={{ lg: "95%", md: "95%", sm: "95%", xs: "95%" }}>
        <Box className="card-box">
          <img
            alt="Maddie"
            width={90}
            src={logo}
            className="animate__animated animate__fadeIn"
          />
          <Typography
            marginTop={3}
            fontWeight="bold"
            textAlign="center"
            color="text.primary"
            fontFamily={"'Nunito Sans', sans-serif"}
            fontSize={{ lg: 35, md: 30, sm: 25, xs: 18 }}
            className="animate__animated animate__fadeIn"
          >
            Thanks!
          </Typography>
          <Typography
            fontWeight="bold"
            textAlign="center"
            color="text.primary"
            fontFamily={"'Nunito Sans', sans-serif"}
            fontSize={{ lg: 35, md: 30, sm: 25, xs: 18 }}
            className="animate__animated animate__fadeIn"
          >
            You will hear from us shortly.
          </Typography>
          <br />
          <img src={check} alt="tick" style={{ width: 120 }} />
          <br />
          <Button
            variant="contained"
            onClick={() => (window.location.href = "https://solarsaver.io")}
            className="animate__animated animate__fadeIn"
            style={{
              background: "black",
              padding: "12px 70px",
              borderRadius: 100,
            }}
          >
            Return Home
          </Button>
        </Box>
      </Box>
    </div>
  );
}
