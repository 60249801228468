import {
  ref,
  getStorage,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import {
  doc,
  setDoc,
  addDoc,
  getDoc,
  query,
  where,
  getDocs,
  updateDoc,
  collection,
  onSnapshot,
  getFirestore,
} from "firebase/firestore";
import {
  signOut,
  getAuth,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithRedirect,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
// google analytics
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCTsruBzxU3OuDX4wiZfb_RWXJGM59_po0",
  authDomain: "hizzy-solar.firebaseapp.com",
  projectId: "hizzy-solar",
  storageBucket: "hizzy-solar.appspot.com",
  messagingSenderId: "1005088106055",
  appId: "1:1005088106055:web:1d7d806fe4379105337362",
  // google analytics
  measurementId: "G-7ZJ89MPCGK",
};

const app = initializeApp(firebaseConfig);
const database = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

// Google Analytics
const analytics = getAnalytics(app);

export {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  uploadBytesResumable,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithRedirect,
  signInWithPopup,
  getDownloadURL,
  uploadBytes,
  onSnapshot,
  collection,
  updateDoc,
  analytics,
  database,
  storage,
  getDocs,
  signOut,
  setDoc,
  getDoc,
  addDoc,
  query,
  where,
  auth,
  doc,
  ref,
};
