import "animate.css";
import * as React from "react";
import Pricing from "./pages/Pricing";
import Contact from "./pages/contact";
import Estimate from "./pages/Services";
import Dashboard from "./pages/Dashboard";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

export default function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/services" element={<Estimate />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/pricing" element={<Pricing />} />
        </Routes>
      </Router>
  );
}
